import { useQuery } from "@tanstack/react-query";
import { getAllDocumentTypes } from "../api/fetchers/dropdownData";

const useGetAllDocTypes = ({ status = "0" }) => {
    return useQuery({
        queryKey: ["all-doc-types"],
        queryFn: () => getAllDocumentTypes(status),
    });
};

export default useGetAllDocTypes;
