import {
    Backdrop,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Skeleton,
    Stack,
    TablePagination,
    Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { MdOutlineNoteAdd } from "react-icons/md";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { BsThreeDotsVertical } from "react-icons/bs";
import GenerateDocForm from "../../components/employee-docs/GenerateDocForm";
import useGetEmployeeDocs from "../../hooks/useGetEmployeeDocs";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import { options } from "./DocumentEditPage";
import useGetSelfDocs from "../../hooks/useGetSelfDocs";
import { useAuth } from "../../hooks/useAuth";

const ManageDocs = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const { user } = useAuth();

    const { data: employeeDocs, isLoading, isError } = useGetSelfDocs({ page, rowsPerPage, id: user?.employee_id });
    console.log("employeeDocs", employeeDocs);

    const [currentData, setCurrentData] = React.useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const menuOpen = Boolean(menuAnchorEl);
    const handleMenuClick = (event, data) => {
        setCurrentData(data);
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const [viewModal, setViewModal] = React.useState(false);
    const handleViewModalOpen = () => {
        setViewModal(true);
        handleMenuClose();
    };

    const handleViewModalClose = () => {
        setViewModal(false);
    };

    const targetRef = useRef();

    const pdfGenerator = async (targetRef) => {
        setLoading(true);
        try {
            await generatePDF(targetRef, options);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return loading ? (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} gap={2}>
                {/* <CircularProgress color="inherit" /> */}
                <Stack spacing={1} sx={{ background: "white", padding: 2, borderRadius: 5 }}>
                    {/* For variant="text", adjust the height via font-size */}
                    {/* <Skeleton variant="text" sx={{ fontSize: "1rem" }} /> */}
                    <Skeleton variant="rectangular" width={40} height={10} />
                    <Skeleton variant="rectangular" width={60} height={10} />
                    <Skeleton variant="rectangular" width={20} height={10} />
                    <Skeleton variant="rectangular" width={180} height={30} />
                    <Skeleton variant="rectangular" width={180} height={50} />
                    <Skeleton variant="rectangular" width={20} height={10} />
                    <Skeleton variant="rectangular" width={40} height={10} />

                    {/* For other variants, adjust the size with `width` and `height` */}
                    {/* <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="rounded" width={210} height={60} /> */}
                </Stack>
                {/* <img src="/assets/images/Download-amico.svg" alt="data" style={{ maxWidth: 200 }} /> */}
                <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
                    <Typography>Please wait while we prepare your document.</Typography>
                    <Typography textAlign={"center"} variant="caption">
                        [Choose wait, if page shows unresponsive.]
                    </Typography>
                </Stack>
            </Stack>
        </Backdrop>
    ) : (
        <Paper sx={{ padding: 3 }}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="h6">My Legal Documents</Typography>
                {/* <Button variant="contained" size="small" onClick={handleClickOpen}>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <MdOutlineNoteAdd style={{ fontSize: 18 }} />
                        Generate Doc
                    </Stack>
                </Button> */}
            </Stack>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <TableContainer>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#SL</TableCell>
                            <TableCell align="left">Employee ID</TableCell>
                            <TableCell align="left">Employee Name</TableCell>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employeeDocs?.data?.length === 0 ||
                            (isError && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No data found
                                    </TableCell>
                                </TableRow>
                            ))}
                        {employeeDocs?.data?.map((doc, index) => (
                            <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell align="left">{doc?.hrm_employee_id ?? "N/A"}</TableCell>
                                <TableCell align="left">{doc?.name}</TableCell>
                                <TableCell align="left">{doc?.document_type_name}</TableCell>
                                <TableCell align="left">{new Date(doc?.created_at).toLocaleDateString()}</TableCell>
                                <TableCell align="right">
                                    <IconButton size="small" color="primary" onClick={(e) => handleMenuClick(e, doc)}>
                                        <BsThreeDotsVertical />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {!isLoading && !isError && (
                <Stack spacing={2} alignItems="end" mt={3}>
                    <TablePagination
                        component="div"
                        count={employeeDocs?.total}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100, employeeDocs?.total]}
                    />
                </Stack>
            )}

            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Generate Employee Document"}</DialogTitle>
                <DialogContent>
                    <GenerateDocForm />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <Menu
                id="basic-menu"
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem onClick={handleViewModalOpen}>View Doc</MenuItem>
                {/* <MenuItem onClick={handleMenuClose}>Delete Doc</MenuItem> */}
            </Menu>
            {/* view doc modal */}
            <Dialog maxWidth={"lg"} open={viewModal} onClose={handleViewModalClose} aria-labelledby="responsive-dialog-title">
                <DialogTitle>{"Document Preview"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div
                            style={{
                                maxWidth: "680px",
                                display: "inline-block",
                                color: "#000",
                            }}
                            ref={targetRef}
                            dangerouslySetInnerHTML={{ __html: currentData?.description }}
                        ></div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleViewModalClose}>
                        Close
                    </Button>
                    <Button size="small" onClick={() => pdfGenerator(targetRef)}>
                        Download Doc
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default ManageDocs;
