import { Box, Button, FormControl, FormHelperText, Grid, OutlinedInput, Stack, Typography } from "@mui/material";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ErrorMessageShow from "../../employees/ErrorMessageShow";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useEmployeeContext } from "../../../context/employee/EmployeeContext";
import Axios from "../../../api/Axios";
import { toast } from "react-toastify";

//Validation
const validationSchema = Yup.object().shape({
    bankName: Yup.string().required("Bank name is required"),
    // branchName: Yup.string().required("Branch name is required"),
    accountName: Yup.string().required("Account name is required"),
    accountNumber: Yup.string().required("Account number is required"),
    // swiftCode: Yup.string().required("Swift code  is required"),
    // routeNumber: Yup.string().required("Routing number is required"),
});

function BankPayment({ methodName, data }) {
    console.log(data, "BankPayment - methodName:", methodName);
    const [errorMessage, setErrorMessage] = useState(null);

    //Router dom
    const navigate = useNavigate();
    const { id } = useParams();

    //Context
    const { token } = useAuth();
    const { basicEmployeeInfo, setTabValue, handleShowTabCondition } = useEmployeeContext();

    //react-hook-form
    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });

    //Submit form
    const handleSubmitForm = async (data) => {
        console.log("handleSubmitForm - data:", data);
        try {
            const res = await Axios.post(
                `/employee/payment`,
                {
                    employee_id: id ? id : 40,
                    // employee_id: id ? id : basicEmployeeInfo?.id,
                    bank_id: methodName,
                    bank: data?.bankName,
                    branch_name: data?.branchName,
                    account_name: data?.accountName,
                    account_number: data?.accountNumber,
                    // swift_code: data?.swiftCode,
                    routing_number: data?.routeNumber,
                },
                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            if (res?.data?.status === "success") {
                toast.success("Payment info  data saved");
                handleShowTabCondition("salary");
                // setTabValue((prev) => prev + 1);
                setErrorMessage(null);
            } else if (res.data.success === "200") {
                toast.info("You don't have employee create payment  info permissions, contact admin");
                navigate("/unauthorized", { replace: true });
            }
        } catch (error) {
            console.log("handleBasicSubmit - error:", error);
            toast.warn("Something went wrong");
            setErrorMessage(error?.response?.data?.error);
        }
    };

    useEffect(() => {
        if (data) {
            const { bank_id, bank, bank_name, account_name, branch_name, account_number, routing_number, swift_code } = data;
            setValue("bankName", bank);
            setValue("branchName", branch_name);
            setValue("accountName", account_name);
            setValue("accountNumber", account_number);
            // setValue("swiftCode", routing_number);
            setValue("routeNumber", routing_number);
        }
    }, [data]);

    return (
        <Box component={"form"} onSubmit={handleSubmit(handleSubmitForm)}>
            <Grid container spacing={2}>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.bankName ? red[700] : ""}>Bank Name</Typography>
                                <OutlinedInput {...field} error={!!formState.errors?.bankName} placeholder="Enter Bank Name" />
                                {!!formState.errors?.bankName ? <FormHelperText error>{errors?.bankName?.message}</FormHelperText> : ""}
                            </FormControl>
                        )}
                        name="bankName"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.branchName ? red[700] : ""}>Branch Name</Typography>
                                <OutlinedInput {...field} error={!!formState.errors?.branchName} placeholder="Enter Branch Name" />
                                {!!formState.errors?.branchName ? <FormHelperText error>{errors?.branchName?.message}</FormHelperText> : ""}
                            </FormControl>
                        )}
                        name="branchName"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.accountName ? red[700] : ""}>Account Name</Typography>
                                <OutlinedInput {...field} error={!!formState.errors?.accountName} placeholder="Enter Account Name" />
                                {!!formState.errors?.accountName ? <FormHelperText error>{errors?.accountName?.message}</FormHelperText> : ""}
                            </FormControl>
                        )}
                        name="accountName"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.accountNumber ? red[700] : ""}>Account Number</Typography>
                                <OutlinedInput {...field} error={!!formState.errors?.accountNumber} placeholder="Enter Account Number" />
                                {!!formState.errors?.accountNumber ? <FormHelperText error>{errors?.accountNumber?.message}</FormHelperText> : ""}
                            </FormControl>
                        )}
                        name="accountNumber"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                {/* <Grid item lg={3} md={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl size={"small"} fullWidth variant="outlined">
                <Typography
                  color={!!formState.errors?.swiftCode ? red[700] : ""}
                >
                  Swift Code
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.swiftCode}
                  placeholder="Enter Swift Code"
                />
                {!!formState.errors?.swiftCode ? (
                  <FormHelperText error>
                    {errors?.swiftCode?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="swiftCode"
            control={control}
            defaultValue=""
          />
        </Grid> */}
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.routeNumber ? red[700] : ""}>Routing Number</Typography>
                                <OutlinedInput {...field} error={!!formState.errors?.routeNumber} placeholder="Enter Routing Number" />
                                {!!formState.errors?.routeNumber ? <FormHelperText error>{errors?.routeNumber?.message}</FormHelperText> : ""}
                            </FormControl>
                        )}
                        name="routeNumber"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
            </Grid>
            <ErrorMessageShow handleError={errorMessage} />

            <Stack direction="row" justifyContent="end" sx={{ mt: 2 }}>
                <Button variant="contained" type={"submit"}>
                    Save
                </Button>
            </Stack>
        </Box>
    );
}

export default BankPayment;
