import { Box, Divider, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import CustomTabPanel, { a11yProps } from "../../components/tab/MuiTabLabel";
import BasicInfoTabNew from "../../components/employee-tab/basic/BasicInfoTabNew";
import SalaryInfoTabNew from "../../components/employee-tab/Salary/SalaryInfoTabNew";
import LeaveTabNew from "../../components/employee-tab/leave/LeaveTabNew";
import AttachmentTabNew from "../../components/employee-tab/attachment/AttachmentTabNew";
import ContactTabNew from "../../components/employee-tab/contact/ContactTabNew";
import { useEmployeeContext } from "../../context/employee/EmployeeContext";
import { useParams } from "react-router-dom";
import PaymentInfoTabNew from "../../components/employee-tab/payment/PaymentInfoTabNew";
import ReferenceTabNew from "../../components/employee-tab/reference/ReferenceTabNew";
import NomineeTabNew from "../../components/employee-tab/nominee/NomineeTabNew";
import CustomFieldsTabNew from "../../components/employee-tab/custom-fileds/CustomFieldsTabNew";
import LoginTabNew from "../../components/employee-tab/login/LoginTabNew";
import { useEffect } from "react";

function EmployeeAddPageNew() {
    //Context
    const { tabValue, setTabValue, basicEmployeeInfo, tabConditionShow, handleChangeTab } = useEmployeeContext();

    // Setting the Initial Tab Position -> 0
    useEffect(() => {
        setTabValue(0);
    }, []);

    const { id } = useParams();

    return (
        <Paper elevation={2} sx={{ p: 2 }}>
            {id ? (
                <Stack direction={"row"} justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Typography variant="h6">
                        Name:
                        {basicEmployeeInfo?.name ?? "N/A"}
                    </Typography>
                    <Typography variant="h6">
                        Email:
                        {basicEmployeeInfo?.email ?? "N/A"}
                    </Typography>
                </Stack>
            ) : (
                <>
                    <Typography variant="h5" color="#0F3F62">
                        Add Employees
                    </Typography>
                </>
            )}
            <Divider sx={{ mt: 2 }} />

            <Box sx={{ width: "100%", mt: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleChangeTab}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Basic Info" {...a11yProps(0)} disabled={id ? false : tabConditionShow?.basic} />
                        <Tab label="Payment Info" {...a11yProps(1)} disabled={id ? false : tabConditionShow?.payment} />
                        <Tab label="Salary Info" {...a11yProps(2)} disabled={id ? false : tabConditionShow?.salary} />
                        {/* <Tab
              label="Positional Info"
              {...a11yProps(3)}
              disabled={id ? false : tabConditionShow?.positional}
            />
            <Tab
              label="Biographical Info"
              {...a11yProps(4)}
              disabled={id ? false : tabConditionShow?.bio}
            /> */}
                        <Tab label="Leaves" {...a11yProps(3)} disabled={id ? false : tabConditionShow?.leaves} />
                        <Tab label="Attachments" {...a11yProps(4)} disabled={id ? false : tabConditionShow?.attachment} />
                        <Tab label="Reference" {...a11yProps(5)} disabled={id ? false : tabConditionShow?.reference} />
                        <Tab label="Nominee" {...a11yProps(6)} disabled={id ? false : tabConditionShow?.nominee} />
                        <Tab label="Emergency Contact" {...a11yProps(7)} disabled={id ? false : tabConditionShow?.contact} />
                        {/* <Tab label="Custom fields" {...a11yProps(8)} disabled={id ? false : tabConditionShow?.customField} /> */}
                        {id ? <Tab label="Login" {...a11yProps(8)} disabled={id ? false : tabConditionShow?.login} /> : null}
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                    <BasicInfoTabNew />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                    {tabConditionShow?.payment && !id ? <Typography variant="h6">Please fill up the previous tab</Typography> : <PaymentInfoTabNew />}
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                    {tabConditionShow?.salary && !id ? <Typography variant="h6">Please fill up the previous tab</Typography> : <SalaryInfoTabNew />}
                </CustomTabPanel>

                {/* <CustomTabPanel value={tabValue} index={3}>
          {tabConditionShow?.positional && !id ? (
            <Typography variant="h6">
              Please fill up the previous tab
            </Typography>
          ) : (
            <PositionalInfoTabNew />
          )}
        </CustomTabPanel> */}

                {/* <CustomTabPanel value={tabValue} index={4}>
          {tabConditionShow?.bio && !id ? (
            <Typography variant="h6">
              Please fill up the previous tab
            </Typography>
          ) : (
            <BioGraphicalTabNew />
          )}
        </CustomTabPanel> */}

                <CustomTabPanel value={tabValue} index={3}>
                    {tabConditionShow?.leaves && !id ? <Typography variant="h6">Please fill up the previous tab</Typography> : <LeaveTabNew />}
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={4}>
                    {tabConditionShow?.attachment && !id ? (
                        <Typography variant="h6">Please fill up the previous tab</Typography>
                    ) : (
                        <AttachmentTabNew />
                    )}
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={5}>
                    {tabConditionShow?.reference && !id ? <Typography variant="h6">Please fill up the previous tab</Typography> : <ReferenceTabNew />}
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={6}>
                    {tabConditionShow?.nominee && !id ? (
                        <Typography variant="h6">Please fill up the previous tab</Typography>
                    ) : (
                        <>{tabValue === 6 && <NomineeTabNew />}</>
                    )}
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={7}>
                    {tabConditionShow?.contact && !id ? <Typography variant="h6">Please fill up the previous tab</Typography> : <ContactTabNew />}
                </CustomTabPanel>
                {/* <CustomTabPanel value={tabValue} index={8}>
                    {tabConditionShow?.customField && !id ? (
                        <Typography variant="h6">Please fill up the previous tab</Typography>
                    ) : (
                        <CustomFieldsTabNew />
                    )}
                </CustomTabPanel> */}
                {id ? (
                    <CustomTabPanel value={tabValue} index={8}>
                        {tabConditionShow?.login && !id ? <Typography variant="h6">Please fill up the previous tab</Typography> : <LoginTabNew />}
                    </CustomTabPanel>
                ) : null}
            </Box>
        </Paper>
    );
}

export default EmployeeAddPageNew;
