import { toast } from "react-toastify";
import Axios from "../Axios";

export const getPayrollList = async (page, limit, storeFilterInfo, employeeId) => {
    try {
        const res = await Axios.get(
            `/payroll?limit=${limit}&page=${page}`,

            {
                params: {
                    month: storeFilterInfo?.monthValue,
                    year: storeFilterInfo?.yearValue,
                    employee_id: employeeId ? employeeId.value : null,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        return res.data;
    } catch (error) {
        console.log("error", error);
        if (error?.response?.status === 422) {
            toast.warn("Data not found");
        } else {
            toast.error(error?.message);
        }
    }
};

export const getPayrollListBySupervisor = async (page, limit, storeFilterInfo, employeeId) => {
    try {
        const res = await Axios.post(
            `/payroll/all-by-supervisor?limit=${limit}&page=${page}`,
            {},
            {
                params: {
                    month: storeFilterInfo?.monthValue,
                    year: storeFilterInfo?.yearValue,
                    employee_id: employeeId ? employeeId.value : null,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        return res.data;
    } catch (error) {
        console.log("error", error);
        if (error?.response?.status === 422) {
            toast.warn("Data not found");
        } else {
            toast.error(error?.message);
        }
    }
};

export const getSalaryInvoice = async (month, year) => {
    try {
        const res = await Axios.post(
            `/salary/sheet/bank`,
            {
                month: month,
                year: year,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        return res.data;
    } catch (error) {
        console.log("error", error);
        if (error?.response?.status === 422) {
            toast.warn("Data not found");
        } else {
            toast.error(error?.message);
        }
    }
};

export const getBankAdvice = async (storeFilterInfo) => {
    try {
        const res = await Axios.post(
            `/salary/sheet/bank`,
            {
                month: storeFilterInfo?.monthValue,
                year: storeFilterInfo?.yearValue,
            },

            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        return res.data;
    } catch (error) {
        console.log("error", error);
        if (error?.response?.status === 422) {
            toast.warn("Data not found");
        } else {
            toast.error(error?.message);
        }
    }
};

export const getSalarySheetList = async (page = 0, rowsPerPage = 10, month, year) => {
    try {
        const res = await Axios.post(
            `/salary/sheet?page=${page + 1}&limit=${rowsPerPage}`,
            {
                month,
                year,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        return res.data;
    } catch (error) {
        console.log("error", error);
        if (error?.response?.status === 422) {
            toast.warn("Data not found");
        } else {
            toast.error(error?.message);
        }
    }
};

export const getSalarySheetDataWithId = async (id, month, year) => {
    try {
        const res = await Axios.post(
            `/salary/sheet/edit`,
            {
                month,
                year,
                salary_sheed_id: id,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        return res.data;
    } catch (error) {
        console.log("error", error);
        if (error?.response?.status === 422) {
            toast.warn("Data not found");
        } else {
            toast.error(error?.message);
        }
    }
};

export const getAllChallanYear = async () => {
    try {
        const res = await Axios.get("/challan-year", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};
