import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { OutlinedInput } from "@mui/material";
import { AiTwotoneCalendar } from "react-icons/ai";

const DateInput = ({
  inputProps,
  inputValue = null,
  onChange = () => {},
  isError = false,
  isClearable = true,
  monthPicker = false,
}) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div style={{ width: "100%", position: "relative" }} onClick={onClick}>
      <OutlinedInput
        {...inputProps}
        value={value}
        ref={ref}
        error={isError}
        placeholder={monthPicker ? "Select month" : "dd/mm/yyyy"}
      />
      {value.length === 0 && (
        <AiTwotoneCalendar
          size={20}
          style={{
            position: "absolute",
            right: 8,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />
      )}
    </div>
  ));
  return (
    <DatePicker
      dateFormat={monthPicker ? "MM/yyyy" : "dd/MM/yyyy"}
      selected={inputValue}
      onChange={(date) => onChange(date)}
      customInput={<ExampleCustomInput />}
      isClearable={isClearable}
      showYearDropdown
      scrollableYearDropdown
      yearDropdownItemNumber={122}
      showMonthYearPicker={monthPicker}
    />
  );
};

export default DateInput;
