import { useQuery } from "@tanstack/react-query";
import { getLeaves } from "../api/fetchers/commonFetcher";

const useGetLeaves = ({ enabled = true }) => {
    return useQuery({
        queryKey: ["get-leaves"],
        queryFn: () => getLeaves(),
        enabled: enabled,
    });
};

export default useGetLeaves;
