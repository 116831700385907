import { useQuery } from '@tanstack/react-query';
import { getEmployeeById } from '../api/fetchers/employeeFetcher';

const useEmployee = ({ id }) => {
    return useQuery({
        queryKey: ['employee'],
        queryFn: () => getEmployeeById(id),
        enabled: !!id
    });
};

export default useEmployee;