import { useQuery } from "@tanstack/react-query";
import { getAllSupervisors } from "../api/fetchers/dropdownData";

const useGetAllSupervisors = () => {
    return useQuery({
        queryKey: ["all-supervisors"],
        queryFn: () => getAllSupervisors(),
    });
};

export default useGetAllSupervisors;
