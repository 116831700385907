import { toast } from "react-toastify";

export function handleErrorMessage(error) {
  if (
    error?.response?.data?.errors !== null &&
    error?.response?.data?.errors?.length > 0
  ) {
    toast.warn(error?.response?.data?.errors[0]);
  } else {
    toast.warn(error?.response?.data?.message);
  }
}
