import { Controller, useForm } from "react-hook-form";
import {
    Avatar,
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiFillEye, AiFillEyeInvisible, AiOutlineCamera } from "react-icons/ai";
import ReactSelect from "react-select";
import countries from "../../../_mockData/countries";
import ErrorMessageShow from "../../employees/ErrorMessageShow";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Axios from "../../../api/Axios";
import { useAuth } from "../../../hooks/useAuth";
import { useEmployeeContext } from "../../../context/employee/EmployeeContext";
import { renderFile } from "../../../utils/utils";
import usePayFrequencyList from "../../../hooks/usePayFrequencyList";
import { maritalStatusData } from "../../../utils/constants";
import useGetAllGrades from "../../../hooks/useGetAllGrades";
import useGetAllSupervisors from "../../../hooks/useGetAllSupervisors";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const genderData = [
    { id: 1, label: "Male", value: "Male" },
    { id: 2, label: "Female", value: "Female" },
    { id: 3, label: "Others", value: "Others" },
];

const bloodValues = [
    { value: "O-", label: "O-" },
    { value: "O+", label: "O+" },
    { value: "B-", label: "B-" },
    { value: "B+", label: "B+" },
    { value: "A-", label: "A-" },
    { value: "A+", label: "A+" },
    { value: "AB-", label: "AB-" },
    { value: "AB+", label: "AB+" },
];

const gradeValues = [
    { value: "1", label: "Grade 1-2" },
    { value: "2", label: "Grade 3-4" },
    { value: "3", label: "Grade 5-6" },
];

function BasicInfoTabNew() {
    const [files, setFiles] = useState([]);
    const [basicEmployeeData, setBasicEmployeeData] = useState(null);
    const [branches, setBranches] = useState([]);
    const [shifts, setShifts] = useState([]);
    const [roles, setRoles] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordAlt, setShowPasswordAlt] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    //Router dom
    const navigate = useNavigate();
    const { id } = useParams();

    //Context
    const { token } = useAuth();
    const { basicEmployeeInfo, setTabValue, setBasicEmployeeInfo, handleShowTabCondition } = useEmployeeContext();

    //Validation
    const validationSchema = Yup.object().shape({
        // attDevId: Yup.number().required("Attendance Device ID is Requried").typeError("Enter a number"),
        hrmEmployeeId: Yup.string().required("HRM Employee ID is Required"),
        joinDate: Yup.string().required("Joining Date is Required").nullable(),
        // re_hire_date: Yup.string().nullable(),
        separation_date: Yup.string().nullable(),
        dobDate: Yup.string().required("Date of birth Date is Required").nullable(),
        presentAddress: Yup.string(),
        permanentAddress: Yup.string(),
        branch: Yup.string().required("Branch is Required"),
        shift: Yup.string().required("Shift is required"),
        gender: Yup.string().nullable().required("Gender is required"),
        marital_status: Yup.string().nullable(),
        pay_frequency_id: Yup.string().required("Pay Frequency is required"),
        role: Yup.string().required("Role is required"),
        contract: Yup.string().required("Job Contract is required"),
        department: Yup.string().required("Department is required"),
        branch: Yup.string().required("Branch is required"),
        designation: Yup.string().required("Designation is required"),
        employeeName: Yup.string().required("Employee name is required").min(3, "Minimum 3 characters"),
        email: id ? Yup.string() : Yup.string().required("Email is required").email("Email is invalid"),
        password: id ? Yup.string() : Yup.string().required("Password is required").min(8, "Minimum 8 characters"),
        confirmPassword: id
            ? Yup.string()
            : Yup.string()
                  .required("Confirm Password is required")
                  .oneOf([Yup.ref("password"), null], "Passwords must match"),
    });

    //File upload
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/*": [],
        },
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles?.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
        maxFiles: 1,
    });

    //react-hook-form
    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });

    const { data: supervisors } = useGetAllSupervisors();

    const [supervisorsList, setSupervisorsList] = useState([]);

    useEffect(() => {
        if (supervisors) {
            setSupervisorsList(
                supervisors?.response_data?.map((item) => ({
                    value: item.employee_id,
                    label: `${item.name} - ${item.user_hrm_id}`,
                }))
            );
        }
    }, [supervisors]);

    //Submit form
    const handleBasicSubmit = async (data) => {
        console.log("handleBasicSubmit - data:", data);
        const basicFormData = new FormData();
        basicFormData.append("office_shift_id", data?.shift);
        basicFormData.append("name", data?.employeeName);
        basicFormData.append("phone", data?.phone || "");
        basicFormData.append("email", data?.email);
        basicFormData.append("gender", data?.gender);
        basicFormData.append("marital_status", data?.marital_status || "");
        basicFormData.append("pay_frequency_id", data?.pay_frequency_id);
        basicFormData.append("father_name", data?.fatherName || "");
        basicFormData.append("mother_name", data?.motherName || "");
        basicFormData.append("country", data?.country || "");
        basicFormData.append("spouse_name", data?.spouseName || "");
        basicFormData.append("city", data?.city || "");
        // basicFormData.append("type", data?.employeeType);
        basicFormData.append("present_address", data?.presentAddress || "");
        basicFormData.append("spouse_nid", data?.spouseNid || "");
        basicFormData.append("parmanent_address", data?.permanentAddress || "");
        basicFormData.append("branch_id", data?.branch);
        basicFormData.append("role_id", parseInt(data?.role));
        basicFormData.append("job_contract_id", parseInt(data?.contract));
        basicFormData.append("att_div_id", parseInt(data?.attDevId));
        basicFormData.append("designation_id", parseInt(data?.designation));
        basicFormData.append("department_id", parseInt(data?.department));
        basicFormData.append("hrm_employee_id", data?.hrmEmployeeId);
        basicFormData.append("join_date", data?.joinDate);
        basicFormData.append("employee_grade", data?.grade || "");
        // basicFormData.append("re_hire_date", data?.re_hire_date ?? "");
        basicFormData.append("separation_date", data?.separation_date ?? "");
        basicFormData.append("dob", data?.dobDate || "");
        basicFormData.append("section", data?.section || "");
        basicFormData.append("blood_group", data?.blood_group || "");
        basicFormData.append("supervisor_id", data?.supervisor || "");

        if (files.length > 0) {
            basicFormData.append("image", files[0]);
        }
        if (id && basicEmployeeInfo) {
            basicFormData.append("employee_id", basicEmployeeInfo?.id);
        }
        if (!id) {
            basicFormData.append("password", data?.password);
            basicFormData.append("password_confirmation", data?.confirmPassword);
        }
        try {
            const res = await Axios.post(`/employee/basic`, basicFormData, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            console.log(res?.data?.employee_id, "employee res:", res);
            if (res?.data?.status === "success") {
                toast.success("Basic info data saved");
                // setTabValue((prev) => prev + 1);
                setBasicEmployeeInfo({
                    name: data?.employeeName,
                    email: data?.email,
                    id: res?.data?.employee_id,
                });
                handleShowTabCondition("payment");
                setErrorMessage(null);
            } else if (res.data.success === "200") {
                toast.info("You don't have employee create  permissions, contact admin");
                navigate("/unauthorized", { replace: true });
            }
        } catch (error) {
            console.log("handleBasicSubmit - error:", error);
            toast.warn("Something went wrong");
            setErrorMessage(error?.response?.data?.errors);
        }
    };

    const { payFrequencyList } = usePayFrequencyList();
    const { data: gradeList } = useGetAllGrades();

    //get all branch from api
    const getAllBranches = async () => {
        try {
            const response = await Axios.get("branch/all", {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            setBranches(
                response.data.response_data?.map((item) => ({
                    value: item.name,
                    label: item.name,
                }))
            );
        } catch (error) {
            console.log(error);
        }
    };

    //get all shifts from api
    const getAllShifts = async () => {
        try {
            const response = await Axios.get("office-shift/all", {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            setShifts(
                response.data.response_data?.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))
            );
        } catch (error) {
            console.log(error);
        }
    };

    //get all roles from api
    const getAllRoles = async () => {
        try {
            const response = await Axios.get("role/all", {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            setRoles(
                response.data.response_data?.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))
            );
        } catch (error) {
            console.log(error);
        }
    };

    //get all contracts from api
    const getAllContracts = async () => {
        try {
            const response = await Axios.get("job-contract/all", {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            setContracts(
                response.data.response_data?.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))
            );
        } catch (error) {
            console.log(error);
        }
    };

    //get all contracts from api
    const getAllDepartments = async () => {
        // setLoading(true);
        try {
            const response = await Axios.get("department/all", {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            setDepartments(
                response.data.response_data?.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))
            );
        } catch (error) {
            console.log(error);
        }
    };

    //get all desgination
    const getAllDesignations = async () => {
        // setLoading(true);
        try {
            const response = await Axios.post(
                `/designation/department`,

                {
                    department_id: selectedDepartment?.value,
                },
                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            setDesignations(
                response.data.response_data?.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))
            );
        } catch (error) {
            console.log("getAllDesignations - error:", error);
            setDesignations([]);
            if (error.response.status === 422) {
                toast.warn(
                    "Selected department does not have a designation associated with it. Please add a designation under this department first."
                );
            } else {
                console.log(error.response.status);
            }
        }
    };

    //get basic data
    const getBasicData = async () => {
        // setLoading(true);
        try {
            const response = await Axios.get(`/employee/basic/show/${id}`, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            console.log("getBasicData - response:", response);
            if (response?.data?.status === "success") {
                const {
                    att_div_id,
                    hrm_employee_id,
                    join_date,
                    // re_hire_date,
                    separation_date,
                    dob,
                    branch_id,
                    name,
                    gender,
                    marital_status,
                    father_name,
                    mother_name,
                    email,
                    role_id,
                    job_contract_id,
                    phone,
                    home_phone,
                    business_phone,
                    country,
                    city,
                    designation_id,
                    department_id,
                    type,
                    pay_frequency_id,
                    office_shift_id,
                    parmanent_address,
                    present_address,
                    spouse_name,
                    section,
                    blood_group,
                    spouse_nid,
                    employee_grade_id,
                    supervisor_id,
                } = response?.data?.response_data;
                setBasicEmployeeInfo({
                    name: name,
                    email: email,
                    id: id,
                });
                console.log("getBasicData - name:", name);
                setValue("attDevId", att_div_id);
                setValue("hrmEmployeeId", hrm_employee_id);
                setValue("joinDate", join_date);
                // setValue("grade", employee_grade);
                setValue("grade", employee_grade_id);
                // setValue("re_hire_date", re_hire_date);
                setValue("separation_date", separation_date);
                setValue("dobDate", dob);
                setValue("section", section);
                setValue("blood_group", blood_group);
                setValue("spouseName", spouse_name);
                setValue("spouseNid", spouse_nid);
                setValue("branch", branch_id);
                setValue("employeeName", name);
                setValue("fatherName", father_name);
                setValue("motherName", mother_name);
                setValue("email", email);
                setValue("gender", gender);
                // setValue("re_hire_date", re_hire_date);
                setValue("pay_frequency_id", pay_frequency_id);
                setValue("role", role_id);
                setValue("contract", job_contract_id);
                setValue("phone", phone);
                setValue("homePhone", home_phone);
                setValue("businessPhone", business_phone);
                setValue("country", country);
                setValue("city", city);
                setValue("designation", designation_id);
                setValue("department", department_id);
                setValue("employeeType", type);
                setValue("shift", office_shift_id);
                setValue("marital_status", marital_status);
                // setValue("eidClient", employee_client_id);
                setValue("presentAddress", present_address || "");
                setValue("permanentAddress", parmanent_address || "");
                setValue("supervisor", supervisor_id || "");
                // setSelectedProject(project_id);
                setSelectedDepartment(departments?.filter((department) => department?.value === department_id)[0]);
                setBasicEmployeeData(response?.data?.response_data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAllBranches();
        getAllShifts();
        getAllRoles();
        getAllContracts();
        getAllDepartments();
    }, []);

    useEffect(() => {
        getAllDesignations();
    }, [selectedDepartment]);

    useEffect(() => {
        if (id) {
            getBasicData();
        }
    }, [id]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <form onSubmit={handleSubmit(handleBasicSubmit)}>
                <Grid container spacing={2} justifyContent={"center"} sx={{ mb: 3 }}>
                    <Grid item sm={4} xs={12}>
                        <Stack justifyContent={"center"}>
                            {/* <label htmlFor="uploadImage">
            <Box
              sx={{
                width: 110,
                height: 80,
                background: "#02b19a",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                color: "white",
                cursor: "pointer",
              }}
            >
              <AiOutlineCloudUpload size={22} /> Upload{" "}
            </Box>
          </label> */}
                            {/* <section
            className="container"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "12px",
              flexWrap: "wrap",
            }}
          >
          
          </section> */}

                            <section
                                className="container"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: "12px",
                                    flexWrap: "wrap",
                                }}
                            >
                                <div {...getRootProps({ className: "dropzone" })} style={{ position: "relative" }}>
                                    <input {...getInputProps()} />

                                    <Avatar
                                        // src={files[0]?.preview}
                                        src={files[0]?.preview || renderFile(basicEmployeeData?.image)}
                                        // alt={"Upload"}
                                        sx={{
                                            minWidth: 100,
                                            width: "100px",
                                            height: "100px",
                                            objectFit: "cover",
                                            cursor: "pointer",
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            right: "-8px",
                                            bottom: "2px",
                                            background: "#acacac",
                                            borderRadius: "50%",
                                            width: "40px",
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: "2px solid white",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <AiOutlineCamera size={24} color="white" />
                                    </Box>
                                    {/* {stepData?.image ? (
                <Avatar
                  src={`${process.env.REACT_APP_BASE_URL}/${stepData?.image}`}
                  alt={"Upload"}
                  variant="rounded"
                  sx={{
                    minWidth: 100,
                    width: "100px",
                    height: "80px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <aside style={thumbsContainer}>{thumbs}</aside>
              )} */}
                                </div>
                            </section>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {/*input for employee name*/}

                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.employeeName ? red[700] : ""}>Employee Name*</Typography>
                                    <OutlinedInput {...field} error={!!formState.errors?.employeeName} placeholder="Enter Employee Name" />
                                    {!!formState.errors?.employeeName ? <FormHelperText error>{errors?.employeeName?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="employeeName"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    {/*input field for employee id*/}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.hrmEmployeeId ? red[700] : ""}>Employee ID*</Typography>
                                    <OutlinedInput {...field} error={!!formState.errors?.hrmEmployeeId} placeholder="Enter Employee ID" />
                                    {!!formState.errors?.hrmEmployeeId ? <FormHelperText error>{errors?.hrmEmployeeId?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="hrmEmployeeId"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    {/*input field for attendance device id*/}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.attDevId ? red[700] : ""}>Attendance Device ID</Typography>
                                    <OutlinedInput {...field} error={!!formState.errors?.attDevId} placeholder="Enter Attendance Device ID" />
                                    {!!formState.errors?.attDevId ? <FormHelperText error>{errors?.attDevId?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="attDevId"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.dobDate ? red[700] : ""}>Date of Birth*</Typography>
                                    {/* <DatePicker size="small" error={!!formState.errors?.dobDate} {...field} format="DD/MM/YYYY" /> */}
                                    <OutlinedInput
                                        {...field}
                                        type={"date"}
                                        error={!!formState.errors?.dobDate}
                                        placeholder="Enter Attendance Device ID"
                                    />
                                    {!!formState.errors?.dobDate ? <FormHelperText error>{errors?.dobDate?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="dobDate"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    {/*input for father's name*/}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth>
                                    <Typography color={!!formState.errors?.gender ? red[700] : ""}>Gender*</Typography>
                                    <ReactSelect
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.gender ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.gender ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        options={genderData}
                                        getOptionValue={(option) => option.value}
                                        placeholder={"Select Gender"}
                                        value={field?.value !== "" ? genderData?.filter((gender) => gender?.value === field?.value)[0] : null}
                                        onChange={(selectedOption) => {
                                            field.onChange(selectedOption?.value);
                                        }}
                                    />
                                    {!!formState.errors?.gender ? <FormHelperText error>{errors?.gender?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="gender"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth>
                                    <Typography color={!!formState.errors?.marital_status ? red[700] : ""}>Marital Status</Typography>
                                    <ReactSelect
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.marital_status ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.marital_status ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        options={maritalStatusData}
                                        getOptionValue={(option) => option.value}
                                        placeholder={"Select Marital Status"}
                                        value={field?.value !== "" ? maritalStatusData?.filter((gender) => gender?.value === field?.value)[0] : null}
                                        onChange={(selectedOption) => {
                                            field.onChange(selectedOption?.value);
                                        }}
                                    />
                                    {!!formState.errors?.marital_status ? (
                                        <FormHelperText error>{errors?.marital_status?.message}</FormHelperText>
                                    ) : (
                                        ""
                                    )}
                                </FormControl>
                            )}
                            name="marital_status"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.fatherName ? red[700] : ""}>Father Name</Typography>
                                    <OutlinedInput {...field} error={!!formState.errors?.fatherName} placeholder="Enter Father Name" />
                                    {!!formState.errors?.fatherName ? <FormHelperText error>{errors?.fatherName?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="fatherName"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    {/*input for mother's name*/}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.motherName ? red[700] : ""}>Mother Name</Typography>
                                    <OutlinedInput {...field} error={!!formState.errors?.motherName} placeholder="Enter Mother Name" />
                                    {!!formState.errors?.motherName ? <FormHelperText error>{errors?.motherName?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="motherName"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    {/*input for phone*/}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.phone ? red[700] : ""}>Phone</Typography>
                                    <OutlinedInput {...field} error={!!formState.errors?.phone} placeholder="Enter Phone Number" />
                                    {!!formState.errors?.phone ? <FormHelperText error>{errors?.phone?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="phone"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    {/*input for country*/}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth>
                                    <Typography color={!!formState.errors?.country ? red[700] : ""}>Country</Typography>
                                    <ReactSelect
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.country ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.country ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        name="country"
                                        options={countries}
                                        getOptionValue={(option) => option.id}
                                        placeholder={"Select Country"}
                                        value={field?.value !== "" ? countries?.filter((country) => country?.id === field?.value)[0] : {}}
                                        onChange={(selectedOption) => field.onChange(selectedOption?.id)}
                                    />
                                    {!!formState.errors?.country ? <FormHelperText error>{errors?.country?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="country"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    {/*input for city*/}
                    <Grid item md={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.city ? red[700] : ""}>City</Typography>
                                    <OutlinedInput size={"small"} {...field} error={!!formState.errors?.city} placeholder="Enter City" />
                                    {!!formState.errors?.city ? <FormHelperText error>{errors?.city?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="city"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    {/*Dropdown for Branch*/}
                    <Grid item md={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth>
                                    <Typography color={!!formState.errors?.branch ? red[700] : ""}>Branch*</Typography>
                                    <ReactSelect
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.branch ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.branch ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        options={branches}
                                        getOptionValue={(option) => option.value}
                                        placeholder={"Select Branch"}
                                        value={field?.value !== "" ? branches?.filter((branch) => branch?.value === field?.value)[0] : null}
                                        onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                    />
                                    {!!formState.errors?.branch ? <FormHelperText error>{errors?.branch?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="branch"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    {/*input for present address*/}
                    <Grid item sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.presentAddress ? red[700] : ""}>Present Address</Typography>
                                    <TextField
                                        {...field}
                                        error={!!formState.errors?.presentAddress}
                                        multiline
                                        rows={3}
                                        placeholder="Enter Present Address"
                                    />
                                    {!!formState.errors?.presentAddress ? (
                                        <FormHelperText error>{errors?.presentAddress?.message}</FormHelperText>
                                    ) : (
                                        ""
                                    )}
                                </FormControl>
                            )}
                            name="presentAddress"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    {/*input for permanent address*/}
                    <Grid item sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.permanentAddress ? red[700] : ""}>Permanent Address</Typography>
                                    <TextField
                                        {...field}
                                        error={!!formState.errors?.permanentAddress}
                                        multiline
                                        rows={3}
                                        placeholder="Enter Permanent Address"
                                    />
                                    {!!formState.errors?.permanentAddress ? (
                                        <FormHelperText error>{errors?.permanentAddress?.message}</FormHelperText>
                                    ) : (
                                        ""
                                    )}
                                </FormControl>
                            )}
                            name="permanentAddress"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.blood_group ? red[700] : ""}>Blood Group</Typography>
                                    {/* <DatePicker size="small" error={!!formState.errors?.blood_group} {...field} format="DD/MM/YYYY" /> */}
                                    <ReactSelect
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.blood_group ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.blood_group ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        options={bloodValues}
                                        getOptionValue={(option) => option.value}
                                        placeholder={"Select blood_group"}
                                        value={field?.value !== "" ? bloodValues?.filter((gender) => gender?.value === field?.value)[0] : null}
                                        onChange={(selectedOption) => {
                                            field.onChange(selectedOption?.value);
                                        }}
                                    />
                                    {!!formState.errors?.blood_group ? <FormHelperText error>{errors?.blood_group?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="blood_group"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    {/* input field for spouse name */}
                    <Grid item sm={4} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.spouseName ? red[700] : ""}>Spouse Name</Typography>
                                    <OutlinedInput {...field} error={!!formState.errors?.spouseName} placeholder="Enter Spouse Name" />
                                    {!!formState.errors?.spouseName ? <FormHelperText error>{errors?.spouseName?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="spouseName"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    {/* input field for spouse NID */}
                    <Grid item sm={4} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.spouseNid ? red[700] : ""}>Spouse NID</Typography>
                                    <OutlinedInput {...field} error={!!formState.errors?.spouseNid} placeholder="Enter Spouse's NID Number" />
                                    {!!formState.errors?.spouseNid ? <FormHelperText error>{errors?.spouseNid?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="spouseNid"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    {/*Dropdown for Branch*/}
                    <Grid item md={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth>
                                    <Typography color={!!formState.errors?.grade ? red[700] : ""}>Employee Grade</Typography>
                                    <ReactSelect
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.grade ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.grade ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        options={gradeList?.response_data}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        placeholder={"Select Grade"}
                                        value={
                                            field?.value !== "" ? gradeList?.response_data?.filter((grade) => grade?.id === field?.value)[0] : null
                                        }
                                        onChange={(selectedOption) => field.onChange(selectedOption?.id)}
                                    />
                                    {!!formState.errors?.grade ? <FormHelperText error>{errors?.grade?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="grade"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    {/*input field for joining date*/}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.joinDate ? red[700] : ""}>Joining Date*</Typography>
                                    <OutlinedInput
                                        {...field}
                                        type={"date"}
                                        error={!!formState.errors?.joinDate}
                                        placeholder="Enter Attendance Device ID"
                                    />
                                    {!!formState.errors?.joinDate ? <FormHelperText error>{errors?.joinDate?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="joinDate"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    {/* <Grid item lg={4} sm={6} xs={12}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.re_hire_date ? red[700] : ""}>Re-hire Date</Typography>
                                <OutlinedInput
                                    {...field}
                                    type={"date"}
                                    error={!!formState.errors?.re_hire_date}
                                    placeholder="Enter Attendance Device ID"
                                />
                                {!!formState.errors?.re_hire_date ? <FormHelperText error>{errors?.re_hire_date?.message}</FormHelperText> : ""}
                            </FormControl>
                        )}
                        name="re_hire_date"
                        control={control}
                        defaultValue=""
                    />
                </Grid> */}

                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.separation_date ? red[700] : ""}>Seperation Date</Typography>
                                    <OutlinedInput
                                        {...field}
                                        type={"date"}
                                        error={!!formState.errors?.separation_date}
                                        placeholder="Enter Attendance Device ID"
                                    />
                                    {!!formState.errors?.separation_date ? (
                                        <FormHelperText error>{errors?.separation_date?.message}</FormHelperText>
                                    ) : (
                                        ""
                                    )}
                                </FormControl>
                            )}
                            name="separation_date"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    {/*Dropdown for department*/}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth>
                                    <Typography color={!!formState.errors?.department ? red[700] : ""}>Department*</Typography>
                                    <ReactSelect
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.department ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.department ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        options={departments}
                                        getOptionValue={(option) => option.value}
                                        placeholder={"Select Department"}
                                        value={
                                            field?.value !== "" ? departments?.filter((department) => department?.value === field?.value)[0] : null
                                        }
                                        onChange={(selectedOption) => {
                                            field.onChange(selectedOption?.value);
                                            setSelectedDepartment(selectedOption);
                                        }}
                                    />
                                    {!!formState.errors?.department ? <FormHelperText error>{errors?.department?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="department"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    {/*Dropdown for Designation*/}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth>
                                    <Typography color={!!formState.errors?.designation ? red[700] : ""}>Designation*</Typography>
                                    <ReactSelect
                                        isDisabled={selectedDepartment === null}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.designation ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.designation ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        options={designations}
                                        getOptionValue={(option) => option.value}
                                        placeholder={"Select Designation"}
                                        value={field?.value !== "" ? designations?.filter((branch) => branch?.value === field?.value)[0] : null}
                                        onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                    />
                                    {!!formState.errors?.designation ? <FormHelperText error>{errors?.designation?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="designation"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl size={"small"} fullWidth variant="outlined">
                                    <Typography color={!!formState.errors?.section ? red[700] : ""}>Section</Typography>
                                    {/* <DatePicker size="small" error={!!formState.errors?.section} {...field} format="DD/MM/YYYY" /> */}
                                    <OutlinedInput {...field} type={"text"} error={!!formState.errors?.section} placeholder="Enter Section" />
                                    {!!formState.errors?.section ? <FormHelperText error>{errors?.section?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="section"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    {/*Dropdown for Job Contract*/}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth>
                                    <Typography color={!!formState.errors?.contract ? red[700] : ""}>Job Contract*</Typography>
                                    <ReactSelect
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.contract ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.contract ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        options={contracts}
                                        getOptionValue={(option) => option.value}
                                        placeholder={"Select Job Contract"}
                                        value={field?.value !== "" ? contracts?.filter((contract) => contract?.value == field?.value)[0] : null}
                                        onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                    />
                                    {!!formState.errors?.contract ? <FormHelperText error>{errors?.contract?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="contract"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    {/*Dropdown for Employee Type*/}
                    {/* <Grid item lg={4} sm={6} xs={12}>
        <Controller
          render={({ field, formState }) => (
            <FormControl size={"small"} fullWidth>
              <Typography
                color={!!formState.errors?.employeeType ? red[700] : ""}
              >
                Employee Type*
              </Typography>
              <ReactSelect
                styles={{
                  control: (base) => ({
                    ...base,
                    border: !!formState.errors?.employeeType
                      ? "1px solid #D32F2F"
                      : "1px solid #C4C4C4",
                    "&:hover": {
                      border: !!formState.errors?.employeeType
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                    },
                  }),
                }}
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="employeeType"
                options={employeeOption}
                value={
                  field?.value !== ""
                    ? employeeOption?.filter(
                        (option) => option?.value === field?.value
                      )[0]
                    : setValue("employeeType", "Fulltime")
                }
                onChange={(selectedOption) =>
                  field.onChange(selectedOption?.value)
                }
              />
              {!!formState.errors?.employeeType ? (
                <FormHelperText error>
                  {errors?.employeeType?.message}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          )}
          name="employeeType"
          control={control}
        />
      </Grid> */}

                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth>
                                    <Typography color={!!formState.errors?.pay_frequency_id ? red[700] : ""}>Pay Frequency*</Typography>
                                    <ReactSelect
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.pay_frequency_id ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.pay_frequency_id ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        options={payFrequencyList}
                                        getOptionValue={(option) => option.value}
                                        placeholder={"Select Pay Frequency "}
                                        value={field?.value !== "" ? payFrequencyList?.filter((pay) => pay?.value == field?.value)[0] : null}
                                        onChange={(selectedOption) => {
                                            field.onChange(selectedOption?.value);
                                        }}
                                    />
                                    {!!formState.errors?.pay_frequency_id ? (
                                        <FormHelperText error>{errors?.pay_frequency_id?.message}</FormHelperText>
                                    ) : (
                                        ""
                                    )}
                                </FormControl>
                            )}
                            name="pay_frequency_id"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    {/*Dropdown for Office Shift*/}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth>
                                    <Typography color={!!formState.errors?.shift ? red[700] : ""}>Office Shift*</Typography>
                                    <ReactSelect
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.shift ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.shift ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        options={shifts}
                                        getOptionValue={(option) => option.value}
                                        placeholder={"Select Office Shift"}
                                        value={field?.value !== "" ? shifts?.filter((shift) => shift?.value == field?.value)[0] : null}
                                        onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                    />
                                    {!!formState.errors?.shift ? <FormHelperText error>{errors?.shift?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="shift"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    {/*Dropdown for Role*/}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth>
                                    <Typography color={!!formState.errors?.role ? red[700] : ""}>Role*</Typography>
                                    <ReactSelect
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.role ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.role ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        options={roles}
                                        getOptionValue={(option) => option.value}
                                        placeholder={"Select Role"}
                                        value={field?.value !== "" ? roles?.filter((role) => role?.value == field?.value)[0] : null}
                                        onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                    />
                                    {!!formState.errors?.role ? <FormHelperText error>{errors?.role?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="role"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    {/*Dropdown for Supervisor*/}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth>
                                    <Typography color={!!formState.errors?.supervisor ? red[700] : ""}>Supervisor</Typography>
                                    <ReactSelect
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.supervisor ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.supervisor ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                },
                                            }),
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        options={supervisorsList}
                                        getOptionValue={(option) => option.value}
                                        placeholder={"Select supervisor"}
                                        value={field?.value !== "" ? supervisorsList?.filter((role) => role?.value === field?.value)[0] : null}
                                        onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                    />
                                    {!!formState.errors?.supervisor ? <FormHelperText error>{errors?.supervisor?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="supervisor"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Divider sx={{ my: 2 }} />
                    </Grid>

                    {/*input for email*/}
                    {!id ? (
                        <Grid item lg={4} sm={6} xs={12}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.email ? red[700] : ""}>Email*</Typography>
                                        <OutlinedInput {...field} error={!!formState.errors?.email} placeholder="Enter Email" />
                                        {!!formState.errors?.email ? <FormHelperText error>{errors?.email?.message}</FormHelperText> : ""}
                                    </FormControl>
                                )}
                                name="email"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                    ) : null}
                    {!id ? (
                        <Grid item lg={4} sm={6} xs={12}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.password ? red[700] : ""}>Password</Typography>
                                        <OutlinedInput
                                            {...field}
                                            size={"small"}
                                            placeholder={"Enter Password"}
                                            type={showPassword ? "text" : "password"}
                                            error={!!formState.errors?.password}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {!!formState.errors?.password ? <FormHelperText error>{errors?.password?.message}</FormHelperText> : ""}
                                    </FormControl>
                                )}
                                name="password"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                    ) : null}

                    {!id ? (
                        <Grid item lg={4} sm={6} xs={12}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.confirmPassword ? red[700] : ""}>Confirm Password</Typography>
                                        <OutlinedInput
                                            {...field}
                                            size={"small"}
                                            placeholder={"Repeat Password"}
                                            type={showPasswordAlt ? "text" : "password"}
                                            error={!!formState.errors?.password}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPasswordAlt(!showPasswordAlt)}
                                                        edge="end"
                                                    >
                                                        {showPasswordAlt ? <AiFillEyeInvisible /> : <AiFillEye />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {!!formState.errors?.confirmPassword ? (
                                            <FormHelperText error>{errors?.confirmPassword?.message}</FormHelperText>
                                        ) : (
                                            ""
                                        )}
                                    </FormControl>
                                )}
                                name="confirmPassword"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                    ) : null}
                </Grid>
                <ErrorMessageShow handleError={errorMessage} />

                <Stack direction="row" justifyContent="end" sx={{ mt: 2 }}>
                    <Button variant="contained" type={"submit"}>
                        Save
                    </Button>
                </Stack>
            </form>
        </LocalizationProvider>
    );
}

export default BasicInfoTabNew;
