import { useQuery } from "@tanstack/react-query";
import { getAllEmployee } from "../api/fetchers/employeeFetcher";

const useGetAllEmployees = () => {
    return useQuery({
        queryKey: ["all-employee"],
        queryFn: () => getAllEmployee(),
    });
};

export default useGetAllEmployees;
