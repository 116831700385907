import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FaUserEdit } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoMdNotifications } from "react-icons/io";
import { Paper } from "@mui/material";
import UpdateProfileTab from "../../components/accountSettings/UpdateProfileTab";
import ChangePasswordTab from "../../components/accountSettings/ChangePasswordTab";
import NotificationSettingsTab from "../../components/accountSettings/NotificationSettingsTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function AccountSettingsPage() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper
      sx={{ flexGrow: 1, display: "flex", minHeight: "80vh" }}
      elevation={2}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider", minWidth: "220px" }}
      >
        <Tab
          icon={<FaUserEdit size={24} />}
          iconPosition={"start"}
          label="Update Profile"
          {...a11yProps(0)}
        />
        <Tab
          icon={<RiLockPasswordFill size={24} />}
          iconPosition={"start"}
          label="Change Password"
          {...a11yProps(1)}
        />
        {/* <Tab
          icon={<IoMdNotifications size={24} />}
          iconPosition={"start"}
          label="Notifications"
          {...a11yProps(1)}
        /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <UpdateProfileTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChangePasswordTab />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <NotificationSettingsTab />
      </TabPanel> */}
    </Paper>
  );
}
