import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { red } from "@mui/material/colors";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

const validationSchema = Yup.object().shape({
  text: Yup.string().required("Enter search text first"),
});

function Search({
  handleSearchSubmit,
  searchValue,
  onChange,
  setSearchValue,
  handleClearSearch,
  isClearSearch,
}) {
  console.log("isClearSearch:", isClearSearch);

  //react-hook-form
  const {
    control,
    handleSubmit,

    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleSearchSubmit)} sx={{mt:2}}>
      <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"end"} gap={1}>
        <Box sx={{ minWidth: {md:320, sm: 250, xs: 1 } }}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth variant="outlined" size="small">
                <TextField
                  {...field}
                  error={!!formState.errors?.text}
                  placeholder="Search.."
                  size="small"
                  value={searchValue}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setSearchValue(e.target.value);
                  }}
                />
                {!!formState.errors?.text ? (
                  <FormHelperText error>{errors?.text?.message}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="text"
            control={control}
            defaultValue=""
          />
        </Box>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"end"}
          gap={1}
        >
          <Button variant="contained" type="submit" sx={{minWidth: { sm: 100,  }}}>
            Search
          </Button>
          {/* <Button
            variant="contained"
            type="button"
            
            color="secondary"
            onClick={handleClearSearch}
          >
            Resets
          </Button> */}
          <Collapse orientation="horizontal" in={isClearSearch}>
              <Box>
                <Button
                  variant="contained"
                  type="button"
                  fullWidth
                  color="secondary"
                  onClick={handleClearSearch}
                >
                  Reset
                </Button>
              </Box>
            </Collapse>
        </Stack>
      </Stack>
      {/* <Grid container spacing={2} justifyContent="end" mt={0}>
        <Grid item xs={6} lg={3} sm={5}>
          <Box>
            <Controller
              render={({ field, formState }) => (
                <FormControl fullWidth variant="outlined" size="small">
                  <TextField
                    {...field}
                    error={!!formState.errors?.text}
                    placeholder="Search.."
                    size="small"
                    value={searchValue}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setSearchValue(e.target.value);
                    }}
                  />
                  {!!formState.errors?.text ? (
                    <FormHelperText error>
                      {errors?.text?.message}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              )}
              name="text"
              control={control}
              defaultValue=""
            />
          </Box>
        </Grid>
        <Grid item xs={6} lg={1} sm={2}>
          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            justifyContent={"end"}
            gap={1}
          >
            <Button variant="contained" type="submit" fullWidth>
              Search
            </Button>
            <Button
              variant="contained"
              type="button"
              fullWidth
              color="secondary"
              onClick={handleClearSearch}
            >
              Resets
            </Button>
            <Collapse orientation="horizontal" in={isClearSearch}>
              <Box>
                <Button
                  variant="contained"
                  type="button"
                  fullWidth
                  color="secondary"
                  onClick={handleClearSearch}
                >
                  Reset
                </Button>
              </Box>
            </Collapse>
          </Stack>
        </Grid>
      </Grid> */}
    </Box>
  );
}

export default Search;
