import {
    Button,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import useGetAllSalarySheet from "../../hooks/useGetAllSalarySheet";
import { SlOptionsVertical } from "react-icons/sl";
import { BiSolidReport } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const PayrollReportGrouped = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedSheet, setSelectedSheet] = React.useState(null);
    const [month, setMonth] = React.useState(null);
    const [year, setYear] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();

    const handleViewReport = () => {
        console.log(selectedSheet);
        navigate(`${selectedSheet?.month}/${selectedSheet?.year}/${selectedSheet?.id}`);
        handleClose();
    };

    const { data: salaySheetList, isLoading: sheetLoading, isError: sheetError } = useGetAllSalarySheet({ page, rowsPerPage, month, year });

    return (
        <Paper sx={{ p: 3 }}>
            <Typography variant="h6">Payroll Report Months</Typography>
            <Divider sx={{ my: 2 }} />
            <Stack alignItems={"flex-end"} mb={2}>
                <Stack direction={"row"} alignItems={"center"} gap={2} maxWidth={"50%"}>
                    <FormControl fullWidth size="small">
                        <Typography>Month</Typography>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={month} onChange={(e) => setMonth(e.target.value)}>
                            {months.map((month, index) => (
                                <MenuItem key={index} value={index > 8 ? `${index + 1}` : `0${index + 1}`}>
                                    {month}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Stack>
                        <Typography>Year</Typography>
                        <ReactDatePicker
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date);
                                setYear(moment(date).format("YYYY"));
                            }}
                            showYearPicker
                            dateFormat="yyyy"
                            customInput={<TextField fullWidth size="small" />}
                        />
                    </Stack>
                    <Button
                        sx={{ mt: 3 }}
                        variant="contained"
                        onClick={() => {
                            setMonth(null);
                            setYear(null);
                            setStartDate(null);
                        }}
                    >
                        Reset
                    </Button>
                </Stack>
            </Stack>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>SL#</TableCell>
                            <TableCell>Month</TableCell>
                            <TableCell>Year</TableCell>
                            <TableCell>Created By</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {salaySheetList?.response_data?.data?.map((row, index) => (
                            <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell>{months[parseInt(row.month ?? 0) - 1]}</TableCell>
                                <TableCell>{row.year}</TableCell>
                                <TableCell>{row.create_by}</TableCell>
                                <TableCell>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={(e) => {
                                            handleClick(e);
                                            setSelectedSheet(row);
                                        }}
                                    >
                                        <SlOptionsVertical fontSize={16} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {!sheetLoading && salaySheetList?.response_data?.data?.length > 0 && (
                    <Stack spacing={2} alignItems="end" mt={3}>
                        <TablePagination
                            component="div"
                            count={salaySheetList?.response_data?.total}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[10, 25, 50, 100, salaySheetList?.response_data?.total]}
                        />
                    </Stack>
                )}
            </TableContainer>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem sx={{ display: "flex", alignItems: "center", gap: 1 }} onClick={handleViewReport}>
                    <BiSolidReport /> View Report
                </MenuItem>
            </Menu>
        </Paper>
    );
};

export default PayrollReportGrouped;
