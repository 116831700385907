import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import * as Yup from "yup";
import DatePicker from "react-datepicker";

//Custom Input for date picker
const DayCustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <OutlinedInput
    onClick={onClick}
    value={value}
    ref={ref}
    size="small"
    placeholder="Select Date"
    fullWidth
  />
));

const validationSchema = Yup.object().shape({
  year: Yup.string().required("Year is required"),
  month: Yup.string().required("Month is required"),
});

const AllSelectEmployee = ({ title, handleSubmitForm }) => {
  //react-hook-form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  return (
    <Paper
      elevation={2}
      sx={{
        py: { xs: 2, md: 3, xl: 4 },
        px: { xs: 2, md: 3, xl: 5 },
        width: "100%",
        mx: "auto",
        mb: 3,
      }}
    >
      <Typography variant="h5" color="#0F3F62">
        {title} Generate
      </Typography>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={2}>
          <Grid item md={3} sm={4} xs={6}>
            <Controller
              render={({
                field: { name, value, onChange, onBlur },
                formState,
              }) => (
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      border: !!formState.errors?.year
                        ? "1px solid red"
                        : "1px solid lightgray",
                    },
                  }}
                >
                  <Typography color={!!formState.errors?.year ? red[700] : ""}>
                    Year*
                  </Typography>

                  <DatePicker
                    selected={value}
                    preventOpenOnFocus={true}
                    placeholderText="YYYY-MM-DD"
                    onBlur={onBlur}
                    onChange={(date) => {
                      onChange(date);
                      onBlur();
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    customInput={<DayCustomInput />}
                  />
                  {!!formState.errors?.year ? (
                    <FormHelperText error>
                      {errors?.year?.message}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              )}
              name="year"
              control={control}
              defaultValue=""
            />
          </Grid>
          <Grid item md={3} sm={4} xs={6}>
            <Controller
              render={({
                field: { name, value, onChange, onBlur },
                formState,
              }) => (
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      border: !!formState.errors?.month
                        ? "1px solid red"
                        : "1px solid lightgray",
                    },
                  }}
                >
                  <Typography color={!!formState.errors?.month ? red[700] : ""}>
                    Month*
                  </Typography>

                  <DatePicker
                    selected={value}
                    preventOpenOnFocus={true}
                    placeholderText="YYYY-MM-DD"
                    onBlur={onBlur}
                    onChange={(date) => {
                      onChange(date);
                      onBlur();
                    }}
                    showMonthYearPicker
                    dateFormat="MM"
                    customInput={<DayCustomInput />}
                  />
                  {!!formState.errors?.month ? (
                    <FormHelperText error>
                      {errors?.month?.message}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              )}
              name="month"
              control={control}
              defaultValue=""
            />
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Typography
              sx={{
                opacity: 0,
                pointerEvent: "none",
                display: { md: "block", xs: "none" },
              }}
            >
              Month*
            </Typography>
            <Button variant="contained" type="submit" sx={{ minWidth: 200 }}>
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default AllSelectEmployee;
