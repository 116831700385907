import { Box, Stack, Typography } from "@mui/material";

const UnauthorizedPage = () => {
  return (
    <Box sx={{ height: "75vh", width: "100%" }}>
      <Stack
        sx={{ height: "100%", width: "100%" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img
          style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "cover" }}
          src="/assets/images/security.svg"
          alt=""
        />
        <Typography variant={"h3"} sx={{ mt: 1 }}>
          You do not have access to this page.
        </Typography>
      </Stack>
    </Box>
  );
};

export default UnauthorizedPage;
