export const API_BASE_URL =
  window.location.host === "hrm.frontdeskbd.com"
    ? "https://hrm.goldeninfotech.net"
    : "https://hrm.goldeninfotech.net";

export const monthsInWords = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getCurrentMonthInWords = () => {
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();

  return monthsInWords[currentMonthIndex];
};


export const getMonthName = (monthNumber) => {
  if (monthNumber >= 1 && monthNumber <= 12) {
    return monthsInWords[monthNumber - 1];
  } else {
    return 'Invalid';
  }
}


export const PaymentMethodOptions = [
  {
    label: "Mobile Banking (Bkash)",
    value: "Bkash",
  },
  {
    label: "Mobile Banking (Nagad)",
    value: "Nagad",
  },
  {
    label: "Mobile Banking (Rocket)",
    value: "Rocket",
  },
  {
    label: "Bank Account",
    value: "Bank",
  },
  {
    label: "Agent Banking",
    value: "Agent",
  },
  {
    label: "BEFTN",
    value: "BEFTN",
  },
  {
    label: "Cash",
    value: "Cash",
  },
];


export const renderFile = (filePath) => {
  return `${process.env.REACT_APP_BASE_URL}/${filePath}`;
}