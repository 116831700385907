import { toast } from "react-toastify";
import Axios from "../Axios";

export const getPayFrequencyList = async () => {
    try {
        const res = await Axios.get("/pay-frequency", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getEmployeeById = async (id) => {
    try {
        const res = await Axios.get(`employee/basic/show/${id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};
export const deleteDocumentById = async (documentId) => {
    try {
        const res = await Axios.delete(`/employee/attachment/delete/${documentId}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getGradeList = async () => {
    try {
        const res = await Axios.get("/grade/all", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getAllEmployee = async () => {
    try {
        const res = await Axios.post(
            "/employee/all",
            {},
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};
