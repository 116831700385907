import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { CiLocationArrow1 } from "react-icons/ci";
import GenerateDocForm from "../../components/employee-docs/GenerateDocForm";
import { Link } from "react-router-dom";

const GenerateDocs = () => {
    return (
        <Paper sx={{ padding: 3 }}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="h6">Doc Generate</Typography>
                <Link to={"/employee-docs/manage"}>
                    <Button variant="contained" size="small">
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                            <CiLocationArrow1 style={{ fontSize: 18 }} />
                            Manage Docs
                        </Stack>
                    </Button>
                </Link>
            </Stack>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Box sx={{ maxWidth: 450, margin: "0 auto" }}>
                <GenerateDocForm />
            </Box>
        </Paper>
    );
};

export default GenerateDocs;
