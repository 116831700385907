import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ErrorMessageShow from "../../employees/ErrorMessageShow";
import Axios from "../../../api/Axios";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useEmployeeContext } from "../../../context/employee/EmployeeContext";
import { toast } from "react-toastify";

//Validation
const validationSchema = Yup.object().shape({
  phone: Yup.number()
    .required("Enter phone number")
    .min(8, "Minium 8 digit is required")
    .typeError("Enter a number"),
});

function MobileBanking({ methodName }) {
  const [errorMessage, setErrorMessage] = useState(null);

  //Router dom
  const navigate = useNavigate();
  const { id } = useParams();

  //Context
  const { token } = useAuth();
  const {
    basicEmployeeInfo,
    setTabValue,
    handleShowTabCondition,
  } = useEmployeeContext();

  //react-hook-form
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  //Submit form
  const handleSubmitForm = async (data) => {
    console.log("handleSubmitForm - data:", data);
    try {
      const res = await Axios.post(
        `/employee/payment`,
        {
          // employee_id: id ? id : 40,
          employee_id: id ? id : basicEmployeeInfo?.id,
          bank: methodName,
          bank_id: methodName,
          account_number: data?.phone,
        },
        {
          headers: {
            Authorization: token
              ? "Bearer " + token
              : "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      console.log(res?.data?.employee_id, "employee res:", res);
      if (res?.data?.status === "success") {
        toast.success("Payment info  data saved");
        handleShowTabCondition("salary");
        // setTabValue((prev) => prev + 1);
        setErrorMessage(null);
      } else if (res.data.success === "200") {
        toast.info(
          "You don't have employee create payment  info permissions, contact admin"
        );
        navigate("/unauthorized", { replace: true });
      }
    } catch (error) {
      console.log("handleBasicSubmit - error:", error);
      toast.warn("Something went wrong");
      setErrorMessage(error?.response?.data?.error);
    }
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid container spacing={1}>
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl size={"small"} fullWidth variant="outlined">
                <Typography color={!!formState.errors?.phone ? red[700] : ""}>
                  Phone
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.phone}
                  placeholder="Enter Phone Number"
                />
                {!!formState.errors?.phone ? (
                  <FormHelperText error>
                    {errors?.phone?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="phone"
            control={control}
            defaultValue=""
          />
        </Grid>
      </Grid>
      <ErrorMessageShow handleError={errorMessage} />

      <Stack direction="row" justifyContent="end" sx={{ mt: 2 }}>
        <Button variant="contained" type={"submit"}>
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default MobileBanking;
