const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;


/*

  const [loading, setLoading] = useState(true);
       setLoading(true);
           setLoading(false);

             if (loading) {
    return <LoadingPage />;
  }


     py: 3,
          px: { lg: 4, md: 3, xs: 2 },
          maxWidth: " 1200px",
          width: "100%",
          mx: "auto",

          <Box sx={{ maxWidth: "1100px", width: "100%", mx: "auto" }}> </Box>


          px: { lg: 5, md: 3, xs: 2}

<StyledTableCell>Sat Start </StyledTableCell>
                <StyledTableCell>Sat End </StyledTableCell>
                <StyledTableCell>Sat Overtime</StyledTableCell>
                <StyledTableCell>Sat Weekend</StyledTableCell>
                <StyledTableCell>Sun Start </StyledTableCell>
                <StyledTableCell>Sun End </StyledTableCell>
                <StyledTableCell>Sun Overtime</StyledTableCell>
                <StyledTableCell>Sun Weekend</StyledTableCell>
                <StyledTableCell>Mon Start </StyledTableCell>
                <StyledTableCell>Mon End </StyledTableCell>
                <StyledTableCell>Mon Overtime</StyledTableCell>
                <StyledTableCell>Mon Weekend</StyledTableCell>
                <StyledTableCell>Tue Start </StyledTableCell>
                <StyledTableCell>Tue End </StyledTableCell>
                <StyledTableCell>Tue Overtime</StyledTableCell>
                <StyledTableCell>Tue Weekend</StyledTableCell>
                <StyledTableCell>Wed Start </StyledTableCell>
                <StyledTableCell>Wed End </StyledTableCell>
                <StyledTableCell>Wed Overtime</StyledTableCell>
                <StyledTableCell>Wed Weekend</StyledTableCell>
                <StyledTableCell>Thu Start </StyledTableCell>
                <StyledTableCell>Thu End </StyledTableCell>
                <StyledTableCell>Thu Overtime</StyledTableCell>
                <StyledTableCell>Thu Weekend</StyledTableCell>
                <StyledTableCell>Fri Start </StyledTableCell>
                <StyledTableCell>Fri End </StyledTableCell>
                <StyledTableCell>Fri Overtime</StyledTableCell>
                <StyledTableCell>Fri Weekend</StyledTableCell>



*/
