import { Box, Collapse, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import MobileBanking from "./MobileBanking";
import BankPayment from "./BankPayment";
import Axios from "../../../api/Axios";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

//Payment Data
const paymentOptionList = [
    { id: 1, label: "Mobile Banking(Bkash)", value: "bkash" },
    { id: 2, label: "Mobile Banking(Nagad)", value: "nagad" },
    { id: 3, label: "Mobile Banking(Rocket)", value: "rocket" },
    { id: 4, label: "Bank Account", value: "bank" },
    { id: 5, label: "Agent Banking", value: "agentBank" },
    { id: 6, label: "BEFTN", value: "beftn" },
    { id: 6, label: "Cash", value: "cash" },
];

//Condition value
const BKASHNAME = "bkash";
const NAGADNAME = "nagad";
const ROCKETNAME = "rocket";
const BANKNAME = "bank";
const AGENTBANKNAME = "agentBank";
const BEFTNNAME = "beftn";

function PaymentInfoTabNew() {
    const [paymentMethod, setPaymentMethod] = useState({
        id: 1,
        label: "Mobile Banking(Bkash)",
        value: "bkash",
    });
    const [storePaymentData, setStorePaymentData] = useState({});

    //Router dom
    const { id } = useParams();

    //Context
    const { token } = useAuth();

    //get position data
    const getPaymentData = async () => {
        try {
            const response = await Axios.get(`/employee/payment/show/${id}`, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });

            if (response?.data?.status === "success") {
                const filterValue = paymentOptionList?.filter((item) => item?.value === response?.data?.response_data?.bank_id);
                console.log(filterValue, "getPaymentData - response:", response?.data?.response_data);
                setStorePaymentData(response?.data?.response_data);
                setPaymentMethod(filterValue[0]);
            }
        } catch (error) {
            console.log(error);
            toast.warn("Unable to get payment data");
        }
    };

    useEffect(() => {
        if (id) {
            getPaymentData();
        }
    }, [id]);

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item lg={4} sm={6} xs={12}>
                    <Typography>Payment Method </Typography>
                    <ReactSelect
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        options={paymentOptionList}
                        getOptionValue={(option) => option.value}
                        placeholder={"Select Payment Method"}
                        value={paymentMethod}
                        onChange={(selectedOption) => setPaymentMethod(selectedOption)}
                    />
                </Grid>
            </Grid>
            <Divider sx={{ mt: 2 }} />{" "}
            <Box sx={{ mt: 3 }}>
                <Collapse in={paymentMethod?.value !== (BANKNAME || BEFTNNAME)}>
                    <MobileBanking methodName={paymentMethod?.value} data={storePaymentData} />
                </Collapse>
                <Collapse in={paymentMethod?.value === (BANKNAME || BEFTNNAME)}>
                    <BankPayment methodName={paymentMethod?.value} data={storePaymentData} />
                </Collapse>
            </Box>
        </Box>
    );
}

export default PaymentInfoTabNew;
