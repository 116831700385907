import { useQuery } from "@tanstack/react-query";
import { getGradeList } from "../api/fetchers/employeeFetcher";

const useGetAllGrades = () => {
    return useQuery({
        queryKey: ["all-grades"],
        queryFn: () => getGradeList(),
    });
};

export default useGetAllGrades;
