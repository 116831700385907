import { toast } from "react-toastify";
import Axios from "../Axios";

export const getAppConfig = async (name) => {
    try {
        const res = await Axios.post(
            "/setting",
            {
                name: name,
            },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const updateAppConfig = async (data) => {
    try {
        const res = await Axios.post("/setting/create/update", data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getMessageNotification = async (name) => {
    try {
        const res = await Axios.get("message/new-notification", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getEmployeeProfile = async (id) => {
    try {
        const res = await Axios.get(`employee/profile/${id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getLeaves = async () => {
    try {
        const res = await Axios.get("leave", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};
