import { useQuery } from "@tanstack/react-query";
import { getEmployeeProfile } from "../api/fetchers/commonFetcher";

const useGetEmployeeProfile = ({ enabled = true, id }) => {
    console.log("Employee ID: ", id, "enabled", enabled);
    return useQuery({
        queryKey: ["employees-profile"],
        queryFn: () => getEmployeeProfile(id),
        enabled: enabled,
    });
};

export default useGetEmployeeProfile;
