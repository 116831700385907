import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Button, FormControl, FormHelperText, Grid, OutlinedInput, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Axios from "../../api/Axios";
import ApiErrorMessage from "../../components/error/ApiErrorMessage";
import { useAuth } from "../../hooks/useAuth";

const validationSchema = Yup.object().shape({
    branchName: Yup.string().required("Year range is required"),
});

const EditChallanYear = ({ handleAddSubmit, handleError, id }) => {
    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
        // defaultValues: {
        //   branchName: branchName?.name,
        // },
    });
    const { token } = useAuth();
    const navigate = useNavigate();

    //Get Branch
    const getBranch = async () => {
        try {
            const res = await Axios.get(
                `/challan-year/show/${id}`,

                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            if (res.data.status === "success") {
                setValue("branchName", res?.data?.response_data?.year_range);
            } else if (res.data.success === "200") {
                toast.info("You don't have role view data  permissions, contact admin");
                navigate("/unauthorized", { replace: true });
            } else {
                toast.warn("Something went wrong");
            }
        } catch (error) {}
    };

    useEffect(() => {
        getBranch();
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(handleAddSubmit)}>
                <Controller
                    render={({ field, formState }) => (
                        <FormControl fullWidth variant="outlined" size="small">
                            <Typography color={!!formState.errors?.branchName ? red[700] : ""}>Year Range*</Typography>
                            <OutlinedInput {...field} error={!!formState.errors?.branchName} placeholder="Enter Role Name" />
                            {!!formState.errors?.branchName ? <FormHelperText error>{errors?.branchName?.message}</FormHelperText> : ""}
                        </FormControl>
                    )}
                    name="branchName"
                    control={control}
                    defaultValue=""
                />
                <ApiErrorMessage handleError={handleError} />
                <Grid container spacing={2} sx={{ my: 2 }}>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={() => reset()} color="inherit" fullWidth>
                            Reset
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" type="submit" fullWidth>
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default EditChallanYear;
